import "../style/tmp-campaign-popup.scss"

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideTmpPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import NgrIcon from "@ng-mw/reol/components/Icon"
import fivePercent from "@resources/icons/fivePercent"

// Components
import Popup, { PopupTitle } from "@modules/popup/Popup"
import Button from "@ng-mw/reol/components/Button"
import PopupContent from "@modules/popup/PopupContent"
import AppLink from "@modules/shared/AppLink"
import PathNames from "@app/const/PathNames"

const TmpCampaignPopup = () => {
    const dispatch = useDispatch<any>()
    const { visible } = useSelector((state: RootState) => state.app.tmpPopup)

    const handleClose = () => {
        dispatch(hideTmpPopup())
    }

    return (
        <Popup
            isOpen={visible}
            isSimple
            isForcedFullscreen
            popupAnimation="slide-up"
            buttons={
                <>
                    <AppLink
                        to={PathNames.Search}
                        skin="none"
                        className="ngr-button"
                        onClick={handleClose}
                    >
                        Start handlelisten her
                    </AppLink>
                    <Button
                        onClick={handleClose}
                        text="Lukk"
                        isCancel
                    />
                </>
            }
        >
            <div className="tmp-campaign-popup">
                <picture>
                    <source
                        srcSet="/images/januarcampaign.webp"
                        type="image/webp"
                    />
                    <source
                        srcSet="/images/januarcampaign.jpeg"
                        type="image/jpeg"
                    />
                    <img
                        className="tmp-campaign-popup__image"
                        src="/images/januarcampaign.jpeg"
                        width="600"
                        height="400"
                        loading="lazy"
                        aria-hidden="true"
                        alt=""
                    />
                </picture>
                <PopupContent isSimple>
                    <PopupTitle>
                        <NgrIcon
                            className="tmp-campaign-popup__textualIcon"
                            icon={fivePercent}
                            size={{ width: "48px", height: "48px" }}
                            title="5% på hele handelen!"
                            aria-label="5% på hele handelen!"
                            isInline
                        />
                        <span aria-hidden={true}>på hele handelen!</span>
                    </PopupTitle>
                    <p>
                        Denne helgen sparer MENY MER-kunder 5% Trumf-bonus på
                        hele handelen. Tilbudet gjelder alle kjøp hos MENY i
                        perioden 1-4. januar. Du får 4 % ekstra Trumf-bonus,
                        totalt 5%, både i nettbutikken og i MENY-butikkene.
                        Siste leveringsdag for ekstra bonus på nettbestillinger
                        er lørdag 4. januar.
                    </p>
                    <PopupTitle className="tmp-campaign-popup__subtitle">
                        Se hvor mye bonus du sparer!
                    </PopupTitle>
                    <p>
                        Bonus og besparelser vises kort tid etter en handel på
                        den digitale kvitteringen her i appen. Handler du i
                        butikk, vil du ikke se oppspart bonus på
                        papirkvitteringen fordi beregningen gjøres etter
                        betaling.
                    </p>
                    <p>
                        For å sikre Trumf-registrering når du handler i butikk
                        må du huske å skanne ditt digitale eller fysiske
                        Trumf-kort ved mobilbetaling, betale med Trumf Pay eller
                        et Trumf-registrert bankkort.
                    </p>
                    <PopupTitle className="tmp-campaign-popup__subtitle">
                        Slik regnes Trumf-bonusen
                    </PopupTitle>
                    <p>
                        1% grunnbonus er alltid inkludert og kommer ikke i
                        tillegg. 5% Trumf-bonus består av 4% kampanjebonus og 1%
                        grunnbonus = 5% Trumf-bonus.
                    </p>
                    <p>
                        Grunnbonusen gjelder kun én gang per handel, selv med
                        flere bonuskampanjer. Handler du taco denne helgen, får
                        du dermed hele 44 % Trumf-bonus på Tacobonus-produktene!
                        Da får du 1 % grunnbonus + 4 % kampanjebonus + 39 %
                        Tacobonus = 44% Trumf-bonus.
                    </p>
                    <p>
                        Det gis ikke Trumf-bonus på pant, spill, gavekort eller
                        post i butikk.
                    </p>
                </PopupContent>
            </div>
        </Popup>
    )
}

export default TmpCampaignPopup
