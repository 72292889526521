import { App, LocalStorage, NativeEventBridge } from "@ng-mw/shared-react-components"
import { core } from "@ng-mw/shared-react-components/framework"
import { Store } from "redux"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import { RootState } from "@app/types"
import { isJwt } from "@app/helpers"
import AppGlobalSettings from "@app/AppGlobalSettings"
import { resetAuthedBaseData, setToken, updateToken } from "@modules/user/store/actions/user"
import { qrLoading } from "@modules/user/store/actions/creators/qrCreator"
import { hideWelcomePopup } from "@app/store/app/appActions"

export default (store: Store<RootState, any>) => {
    const performLogout = () => {
        store.dispatch(resetAuthedBaseData())
        if (!AppGlobalSettings.enableTrumfId) {
            LocalStorage.remove(App.LocalStorage.UserToken)
        }
    }

    core.MessageBroker.registerListener(core.PredefinedMessageTopics.ACCESS_TOKEN_NEEDS_REFRESH, () => {
        LocalStorage.remove("DEBUG_STORED_TOKEN") // remove debug token when it's stale
        NativeEventBridge.broadcastNative(MenyNativeEvent.RefreshAccessToken, { forceRefresh: 0 })
    })

    core.MessageBroker.registerListener(core.PredefinedMessageTopics.TRUMF_USER_WILL_BE_LOGGED_OUT, () => {
        NativeEventBridge.broadcastNative(MenyNativeEvent.ForceLogOut)
        performLogout()
    })

    core.MessageBroker.registerListener(core.PredefinedMessageTopics.LOGOUT_COMPLETE, () => {
        performLogout()
    })

    NativeEventBridge.listen(MenyNativeEvent.LoginComplete, ({ token }: { token: string }) => {
        const legacyToken = LocalStorage.get(App.LocalStorage.UserToken)

        if (isJwt(token) && legacyToken) {
            LocalStorage.remove(App.LocalStorage.UserToken)
        }

        const state = store.getState()
        if (state.user.loginLevel > 0) {
            // This is a step-up - Just update token
            store.dispatch(updateToken(token))
        } else {
            // This is a new login - Set token from scratch
            store.dispatch(setToken(token))
        }

        store.dispatch(hideWelcomePopup())
        // TODO move the next lines to setToken() maybe?
        store.dispatch(qrLoading())
        NativeEventBridge.broadcastNative(MenyNativeEvent.GetTrumfQrCode)
    }, false)

    NativeEventBridge.listen(MenyNativeEvent.FreshAccessToken, ({ accessToken }: { accessToken: string }) => {
        store.dispatch(updateToken(accessToken))
    }, false)
}
