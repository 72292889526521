import type { Reducer } from "redux"
// import { fromUnixTime, isPast, compareDesc } from "date-fns"

import { SettingsState, SettingsActionTypes, SettingsAction } from "./types"

const initialState: SettingsState = {
    loading: false,
    maintenanceMessage: {
        operationsBody: "",
        operationsIsActive: false,
        operationsTitle: "",
        userHidden: false,
    },
    customerServiceMessage: {
        operationsBody: "",
        operationsTitle: "",
        operationsDate: null,
        userHidden: false,
    },
    bannerInfo: {
        enableTopBanner: false,
        disableTopBannerForAuthenticatedUsers: false,
        topBannerText: "",
        bannerType: "",
        topBannerFrom: "",
        topBannerTo: "",
    },
}

const settingsReducer: Reducer<SettingsState, SettingsAction> = (state = initialState, action) => {
    switch (action.type) {
        case SettingsActionTypes.Loading:
            return {
                ...state,
                loading: true,
            }

        case SettingsActionTypes.Loaded:
            return {
                ...state,
                loading: false,
            }

        case SettingsActionTypes.Success: {
            const isAndroid = navigator?.userAgent.toLowerCase().indexOf("android") > -1
            const maintenanceMessage = isAndroid
                ? action.payload.appMaintenanceMessageAndroid
                : action.payload.appMaintenanceMessageIOS

            return {
                ...state,
                maintenanceMessage: {
                    ...maintenanceMessage,
                    userHidden: false,
                },
                customerServiceMessage: {
                    ...action.payload.appCustomerServiceMessage,
                    userHidden: false,
                },
                bannerInfo: {
                    ...action.payload.bannerInfo,

                    // DEBUGGING
                    // topBannerText: "Halla banner her",
                    // bannerType: "meny-mer",
                },
            }
        }

        case SettingsActionTypes.HideMaintenanceMessage: {
            return {
                ...state,
                maintenanceMessage: {
                    ...state.maintenanceMessage,
                    userHidden: true,
                },
            }
        }

        case SettingsActionTypes.HideCustomerServiceMessage: {
            return {
                ...state,
                customerServiceMessage: {
                    ...state.customerServiceMessage,
                    userHidden: true,
                },
            }
        }

        default:
            return state
    }
}

export default settingsReducer
