import { DebugLogger, LocalStorage, NativeEventBridge } from "@ng-mw/shared-react-components"
import MenyNativeEvent from "@app/events/MenyNativeEvent"

const debugToken = LocalStorage.get("DEBUG_STORED_TOKEN")

const fetchTokenFromNative = () => new Promise(resolve => {
    if (debugToken) {
        DebugLogger.log("Load-order-debug: Found DEBUG_STORED_TOKEN... simulating FAST RESPONSE...")
        setTimeout(() => {
            DebugLogger.log("Load-order-debug: Found DEBUG_STORED_TOKEN:", debugToken)
            resolve(debugToken)
        }, 40)
        return
    }
    DebugLogger.log("Load-order-debug: NO DEBUG_STORED_TOKEN, using normal auth")

    const handleFreshToken = ({ accessToken }) => {
        NativeEventBridge.unlisten(handleFreshToken)
        resolve(accessToken) // Her kan vi få vi ASL "none"
    }

    // Get token from native
    NativeEventBridge.listen(MenyNativeEvent.FreshAccessToken, handleFreshToken, false)
    NativeEventBridge.broadcastNative(MenyNativeEvent.RefreshAccessToken, { forceRefresh: 0 })
})

export default fetchTokenFromNative
